import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError  } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { SecurityService } from 'app/main/security/security.service';
import { AuthService } from 'app/main/auth/auth.service';
@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(private router: Router, private _fuseConfigService: FuseConfigService, private securityService: SecurityService, private authService: AuthService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
            // console.log('ERROR INTERCEPTOR');
            if (err.status === 403 && (err.error.message.code === 'S0001' || err.error.message.code === 'S9999')) {
                // Error 403: S0001 - Session expired
                // console.log(err);

                // If the user is security, is checked in and is authenticated, then check out
                // This is to avoid the user to be checked in after the session has expired
                this._fuseConfigService.config.subscribe((config) => {
                    // console.log('isSecurity', config, 'isCheckedIn', this.securityService.isCheckedIn, 'isAuthenticated', this.authService.isAuthenticated);
                    if (config.isSecurity && this.securityService.isCheckedIn && this.authService.isAuthenticated) {
                        // console.log('Check out', config.user.id);
                        this.securityService.checkOutAfterSessionKill(config.user.id).subscribe();
                    }
                });

                this.authService.isAuthenticated = false;
                this.router.navigate(['/auth/login']);
                return throwError(err);
            } else if (err.status === 403 && (err.error.message.code === 'S0002')) {
                this.router.navigate(['/profile']);
                return throwError(err);
            }
            return throwError(err);
        }));
    }
}